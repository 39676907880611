<template>
  <b-modal
    size="lg"
    id="show-document-modal"
    v-model="modalVisible"
    title="Imagen Documento"
    hide-footer
    @close="() => this.$emit('close')"
    @hidden="() => this.$emit('close')"
  >
    <h3>Datos que registro el usuario</h3>
    <ul v-if="client">
      <li>Nombre: {{ client.full_name }}</li>
      <li>Numero identificacion: {{ client.identification_number }}</li>
    </ul>

    <b-img :src="url" fluid />
  </b-modal>
</template>

<script>
import { BModal, BImg } from 'bootstrap-vue'
export default {
  components: {
    BModal,
    BImg,
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      required: true,
      default: false,
    },
    doc: {
      required: true,
      default: () => null,
    },
    client: {
      required: true,
      default: () => null,
    },
  },
  name: 'ShowDocumentModal',
  data() {
    return {
      modalVisible: this.show,
    };
  },
  watch: {
    show: {
      immediate: true,
      handler(val) {
        console.log('val', val)
        this.modalVisible = val;
      },
    },
  },
}
</script>
