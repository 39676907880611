<template>
  <div class="item">
    <div class="container-icon">
      <b-avatar variant="primary" :src="avatarUrl" size="6rem"></b-avatar>
    </div>

    <div class="text-center">
      <h2>Bienvenid@ {{ name }}</h2>
      <p>{{ description }}</p>
    </div>
  </div>
</template>

<script>
import { BCard, BAvatar } from 'bootstrap-vue'

export default {
  props: {
    name: {
      type: String,
      default: 'Usuario',
    },
    avatarUrl: {
      type: String,
      default: null,
    },
    description: {
      type: String,
      default:
        'Hoy es un buen dia para cumplir nuevas metas y rendir en tus actividades',
    },
    color: {
      type: String,
      default: '#6bf182',
    },
  },
  components: {
    BCard,
    BAvatar,
  },
}
</script>

<style lang="scss" scoped>
.item {
  display: flex;
  align-items: center;
  background: #3d52ff;
  padding: 1rem;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  border-radius: 0.5rem;
  flex-wrap: wrap;
  justify-content: center;
  height: 100%;
}

p,
h2 {
  color: white;
}

.container-icon {
  width: 100%;
  display: flex;
  justify-content: center;
}

.icon {
  background: red;
  height: 90px;
  width: 90px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
</style>
