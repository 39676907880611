<template>
  <b-dropdown no-caret variant="outline-primary" style="width: 315px" ref="dropdown" @hide="handleHide($event)" @hidden="isCloseable=false">
    <template #button-content>
      <div class="d-flex align-items-center justify-content-between">
        <span>
          <calendar-icon class="mr-2" color="#1a2ab1" size="20"/>
          {{ text }}
        </span>
        <feather-icon icon="ChevronDownIcon" class="ml-1" size="16" />
      </div>
    </template>
    <div style="width: 315px" class="px-1 mb-2">
      <b-form-datepicker
        class="mb-1"
        @submit.stop.prevent
        id="dropdown-form-email"
        v-model="dateRange[0]"
        locale="es"
        @input="updateRange"
        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
      ></b-form-datepicker>
      <b-form-datepicker
        v-model="dateRange[1]"
        locale="es"
        @input="updateRange"
        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
      ></b-form-datepicker>
    </div>
    <b-dropdown-item v-for="option in options" @click="handleSelect(option)" :key="option.value">
      {{ option.text }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import { BDropdown, BDropdownDivider, BDropdownItem, BFormDatepicker, BDropdownForm, BFormGroup, BFormInput } from 'bootstrap-vue'
import moment from 'moment'
import CalendarIcon from '@/components/icons/Calendar'
import Calendar from './icons/Calendar.vue'
import FeatherIcon from '@/@core/components/feather-icon/FeatherIcon.vue'

export default {
  props: {},
  components: {
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
    BFormDatepicker,
    BDropdownForm,
    BFormGroup,
    BFormInput,
    CalendarIcon,
    Calendar,
    FeatherIcon,
  },
  data() {
    return {
      currentOption: null,
      options: [
        { text: 'En el dia', value: 'today' },
        { text: 'El dia de ayer', value: 'yesterday' },
        { text: 'En la semana', value: 'current-week' },
        { text: 'En el mes', value: 'current-month' },
        { text: 'El mes pasado', value: 'last-month' },
        { text: 'En el año', value: 'current-year' },
      ],
      dateRange: [],
      isCloseable: false,
      customRange: '',
    }
  },
  computed: {
    text() {
      console.log('customRange', this.customRange)
      return this.customRange || this.currentOption?.text
    },
  },
  methods: {
    updateRange() {
      this.$emit('change', [
        moment(this.dateRange[0]).startOf('day').toDate(),
        moment(this.dateRange[1]).endOf('day').toDate(),
      ])
      this.customRange = `${moment(this.dateRange[0]).format('DD/MM/YYYY')} - ${moment(this.dateRange[1]).format('DD/MM/YYYY')}`
      this.closeDropdown()
    },
    handleHide(bvEvent) {
      if (!this.isCloseable) {
        bvEvent.preventDefault();
      } else {
        this.$refs.dropdown.hide();
      }
    },
    closeDropdown() {
      this.isCloseable = true;
      this.$refs.dropdown.hide();
    },
    handleSelect(option) {
      console.log(option)
      this.currentOption = option
      // convert option.value to date
      switch(option.value) {
        case 'today':
          this.dateRange = [
            moment().startOf('day').toDate(),
            moment().endOf('day').toDate(),
          ]
          break
        case 'yesterday':
          this.dateRange = [
            moment().subtract(1, 'days').startOf('day').toDate(),
            moment().subtract(1, 'days').endOf('day').toDate(),
          ]
          break
        case 'current-week':
          this.dateRange = [
            moment().startOf('week').toDate(),
            moment().endOf('week').toDate(),
          ]
          break
        case 'current-month':
          this.dateRange = [
            moment().startOf('month').toDate(),
            moment().endOf('month').toDate(),
          ]
          break
        case 'last-month':
          this.dateRange = [
            moment().subtract(1, 'months').startOf('month').toDate(),
            moment().subtract(1, 'months').endOf('month').toDate(),
          ]
          break
        case 'current-year':
          this.dateRange = [
            moment().startOf('year').toDate(),
            moment().endOf('year').toDate(),
          ]
          break
      }

      this.$emit('change', this.dateRange)
      this.customRange = ''
      this.closeDropdown()
    },
  },
  mounted() {
    this.currentOption = this.currentOption || this.options[0]
    // this.handleSelect(this.currentOption)
  },
}
</script>

<style scoped>
</style>
