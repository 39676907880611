<template>
    <div>
        <div class="d-flex align-items-center">
            <span style="white-space: nowrap;" class="tabbutton" :class="{ tabactive: tab === 'accounts' }"
                @click="changeTab('accounts')"
            >
                Cuentas Bancarias
                {{ accounts && accounts.length > 0 ? '(' + accounts.length + ')' : '' }}
            </span>
            <span style="white-space: nowrap;" class="tabbutton" :class="{ tabactive: tab === 'block_history' }"
                @click="changeTab('block_history')"
                >
                Historial de Bloqueos/Desbloqueos
                {{ blockHistory && blockHistory.length > 0 ? '(' + blockHistory.length + ')' : '' }}
            </span>
        </div>
        <div class="p-2">
            <div v-if="tab === 'accounts'">
                <b-table hover responsive="sm" :items="accounts" :fields="fieldsBankAccounts" show-empty>
                    <template #cell(bank)="data">
                        <div class="d-flex align-items-center">
                            <img style="width: 30px; height: 30px;" v-if="data.item.bank" :src="data.item.bank.icon_url" class="img-rounded mr-1" alt="">
                            <span>{{ data.item.bank ? data.item.bank.name : '' }}</span>
                        </div>
                    </template>

                    <template #cell(bank_account_type)="data">
                        <span>{{ data.item.bank_account_type ? data.item.bank_account_type.name : '' }}</span>
                        <br>
                        <span v-if="data.item.account_photo_url" @click="showAccountData(data.item.account_photo_url, 'Datos de la cuenta bancaria')" class="text-primary text-underline cursor-pointer">Ver datos</span>
                        <div v-else>
                            <span>Nro. <b>{{ data.item.account_number }}</b></span> <br>
                            <span>{{ data.item.identification_type ? `${data.item.identification_type.name}: ` : '' }} <b>{{ data.item.identification_number }}</b></span>
                        </div>
                    </template>

                    <template #cell(owner)="data">
                        <span>{{ data.item.owner ? 'Propia' : 'De un tercero' }}</span>
                    </template>

                    <template #cell(for_transaction_type)="data">
                        <span v-if="data.item.for_transaction_type === 'recharge'">
                            Recargas
                        </span>
                        <span v-if="data.item.for_transaction_type === 'withdraw'">
                            Retiros
                        </span>
                        <span v-if="data.item.for_transaction_type === 'all'">
                            Recargas y Retiros
                        </span>
                    </template>

                    <template #cell(actions)="data">
                        <feather-icon v-if="data.item.for_transaction_type === 'recharge' && hasPermission('delete-bank-account')" icon="TrashIcon" class="text-danger" size="1.5x" @click="deleteBankAccount(data.item.id)" />
                    </template>
                </b-table>
            </div>
            <div v-if="tab === 'block_history'">
                <b-table hover responsive="sm" :items="blockHistory" :fields="fieldsBlockHistory" show-empty>
                    <template #cell(created_at)="data">
                        <small>{{ dateFormat(data.item.created_at, 'MMM D, YYYY - h:mm A') }}</small>
                    </template>

                    <template #cell(created_by)="data">
                        <small>{{ data.item.created_by ? data.item.created_by.full_name : '' }}</small>
                    </template>

                    <template #cell(motive)="data">
                        <small>{{ getUserBlockedMotive(data.item.motive) }}</small>
                    </template>

                    <template #cell(description)="data">
                        <small>{{ data.item.description }}</small>
                    </template>
                </b-table>
            </div>
        </div>
        <b-modal size="lg" id="show-document-modal" v-model="showPicture" title="Datos cuenta bancaria" ok-only ok-title="Cerrar">
            <show-document-modal @close="closeModalPicture()" :show="showPicture" :url="urlPhoto" :detail="user" />
        </b-modal>
    </div>
</template>

<script>
import {
    BTable,
    BModal,
} from 'bootstrap-vue'
import API from '@/libs/axios'
import numberMixins from '@/mixins/numberMixins'
import ShowDocumentModal from '@/views/RechargeDetail/components/ShowDocumentModal'
import { confirmAlert } from '@/helpers/utils'
import { verifyPermission } from '@/router';

export default {
    mixins: [numberMixins],
    components: {
        BTable,
        BModal,
        ShowDocumentModal,
    },
    props: {
        user: {
            type: Object,
            default: () => {},
        },
        accounts: {
            type: Array,
            default: () => [],
        },
        blockHistory: {
            type: Array,
            default: () => [],
        }
    },
    data() {
        return {
            showPicture: false,
            urlPhoto: null,
            tab: 'accounts',
            fieldsBankAccounts: [
                {
                    key: 'bank',
                    label: 'Banco/Medio de Pago',
                },
                {
                    key: 'bank_account_type',
                    label: 'Tipo de cuenta',
                },
                {
                    key: 'owner',
                    label: 'Cuenta propia',
                },
                {
                    key: 'for_transaction_type',
                    label: 'Uso',
                },
                {
                    key: 'actions',
                    label: 'Acciones',
                },
            ],
            fieldsBlockHistory: [
                {
                    key: 'created_at',
                    label: 'Fecha/Hora',
                    sortable: true,
                },
                {
                    key: 'created_by',
                    label: 'Creado por',
                },
                {
                    key: 'motive',
                    label: 'Motivo',
                },
                {
                    key: 'description',
                    label: 'Descripción',
                },
            ]
        }
    },
    methods: {
        hasPermission(permissionKey) {
            return verifyPermission({ namePerm: permissionKey })
        },
        async deleteBankAccount(accountId) {
            const confirmed = await confirmAlert(
                'Eliminar cuenta bancaria',
                '¿Está seguro que desea eliminar esta cuenta bancaria'
            )
            if (confirmed.isConfirmed) {
                const response = await API.delete(`users/delete-bank-account/${accountId}`)
                if (response.data.success) {
                    this.$emit('onDeleteBankAccount')
                } else {
                    alert('Ocurrió un error al eliminar la cuenta bancaria')
                }
            }
        },
        showAccountData(url, title) {
            this.urlPhoto = url
            this.showPicture = true
        },
        closeModalPicture() {
            this.showPicture = false
            this.urlPhoto = null
        },
        changeTab(tab) {
            this.tab = tab
        },
    },
}
</script>

<style scoped>
.tabbutton {
  padding: 1rem;
  font-weight: bold;
  cursor: pointer;
}

.tabactive {
  background-color: #f8f9fa;
  border-bottom: 4px solid #007bff;
}
</style>