<template>
  <b-overlay :show="loading" rounded="sm">
    <div v-if="client">
      <b-card>
        <div class="pb-3">
          <b-button variant="primary" class="btn-icon mr-1" @click="() => $router.go(-3)">
            <feather-icon icon="ArrowLeftIcon" />
          </b-button>
          <b-button variant="success" class="btn-icon mr-1">
            <feather-icon icon="RefreshCwIcon" size="16" />
          </b-button>
        </div>
        <b-row>
          <b-col cols="12" class="d-flex justify-content-between">
            <!-- User Avatar & Action Buttons -->
            <div class="d-flex justify-content-between">
              <div class="d-flex">
                <b-avatar class="mb-1 mr-2" size="6rem" :src="client.avatar_url"
                  :initials="client.initial_letters"></b-avatar>
                <div>
                  <h4 class="mb-0">
                    {{ client.full_name }}
                  </h4>
                  <span class="card-text"><strong>Email:</strong> {{ client.email }}</span>
                  <br />
                  <span class="card-text">
                    <strong>Telefono:</strong> {{ client.phone }}
                  </span>
                  <br />
                  <span class="card-text">
                    <strong>Registrado desde:</strong>
                    {{ dateFormat(client.created_at) }}
                  </span>
                  <br />
                  <span class="card-text">
                    <strong>Tipo de cuenta:</strong> {{ client.account_type }}
                  </span>
                  <br />
                  <span class="card-text" v-if="client.identification_type">
                    <strong>Tipo de identificación:</strong> ({{ client.identification_type.code }}) {{ client.identification_type.name }}
                  </span>
                  <br />
                  <span class="card-text" v-if="client.identification_type">
                    <strong>Numero de identificación:</strong> {{ client.identification_number}}
                  </span>
                  <br />
                  <span class="card-text">
                    <strong>Pais de nacimiento:</strong> {{ client.birth_country.name }}
                  </span>
                  <br />
                  <span class="card-text">
                    <strong>Pais de residencia:</strong> {{ client.residence_country.name }}
                  </span>
                </div>
              </div>
              <div class="text-right">
                <!--verified element-->
                <div v-if="client.verified">
                  <b-badge variant="success" class="ml-1">
                    <feather-icon icon="CheckCircleIcon" size="16" />
                    <span class="align-middle ml-25">Verificado</span>
                  </b-badge>
                </div>
                <div v-else>
                  <b-badge variant="danger" class="ml-1">
                    <feather-icon icon="AlertCircleIcon" size="16" />
                    <span class="align-middle ml-25">No verificado</span>
                  </b-badge>
                </div>
              </div>
            </div>

            <!-- User Stats -->
            <div class="d-flex align-items-center mt-2">
              <div class="d-flex align-items-center mr-2">
                <b-avatar variant="light-danger" rounded>
                  <feather-icon icon="DollarSignIcon" size="18" />
                </b-avatar>
                <div class="ml-1">
                  <h5 class="mb-0" v-if="client.balances">
                    {{ numberFormat(client.balances.pending_balance) }}
                  </h5>
                  <small>Balance pendiente</small>
                </div>
              </div>

              <div class="d-flex align-items-center mr-2">
                <b-avatar variant="light-success" rounded>
                  <feather-icon icon="DollarSignIcon" size="18" />
                </b-avatar>
                <div class="ml-1">
                  <h5 class="mb-0" v-if="client.balances">
                    {{ numberFormat(client.balances.balance) }}
                  </h5>
                  <small>Balance</small>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card>

      <!--Verification data files -->
      <b-card>
        <b-row>
          <b-col cols="12">
            <div>
              <div>
                <div class="d-flex justify-content-between mb-3">
                  <h4 class="mb-0">Verificación de documentos</h4>

                  <b-button @click="showUploadDocument" variant="primary">
                    Subir Documentos
                  </b-button>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="4" v-for="dv in client.document_verifications" :key="dv.id">
            <b-card style="min-height: 280px" :class="['border m-1', {
              'border-success': dv.status === 'completed',
              'border-warning': dv.status === 'in_process',
              'border-danger': dv.status === 'rejected'
            }]" :title="'Documentos de ' +
                      (dv.verification_type === 'identity'
                        ? 'Identidad'
                        : 'Direccion')">


              <h4>Estado: <span :class="['font-weight-bold', {
              'text-success': dv.status === 'completed',
              'text-warning': dv.status === 'in_process',
              'text-danger': dv.status === 'rejected'
            }]">{{ dv.status_text }}</span></h4>

              <ul style="list-style: none">
                <li class="" v-for="file in dv.verification_files" :key="file.id">
                  {{ getNameFileType(file.file_type) }}
                  <b-button @click="showFile(file)" variant="link">Ver</b-button>
                </li>
              </ul>
              <div class="text-right" v-if="!['completed', 'rejected'].includes(dv.status)">
                <b-button @click="approve(dv.id, 'rejected', dv.verification_type)" class="mr-1" variant="danger"
                  size="sm" v-if="permissions_requerid.includes('reject-document')">Rechazar</b-button>

                <b-button @click="approve(dv.id, 'approved', dv.verification_type)" variant="success"
                  v-if="permissions_requerid.includes('approve-document')" size="sm">Aprobar</b-button>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-card>

      <b-card>
        <!-- <b-tabs content-class="mt-3" fill>
          <b-tab title="Recargas">
            <recharges :id="$route.params.id" />
          </b-tab>

          <b-tab title="Retiros">
            <withdrawals :id="$route.params.id" />
          </b-tab>

          <b-tab title="Transacciones">
            <send-money :id="$route.params.id" />
          </b-tab>
        </b-tabs> -->

        <transactions :userId="$route.params.id" />
      </b-card>

      <b-card v-if="client.bank_accounts || client.block_history" title="Otros datos del usuario">
        <other-user-data
          :accounts="client.bank_accounts"
          :block-history="client.block_history"
          :user="client"
          @onDeleteBankAccount="getDetail()"
        />
      </b-card>
    </div>

    <show-document-modal @close="closeDocumentModal()" :show="showDocument" :url="urlDocument" :doc="verifyFile" :client="client" />

    <b-sidebar
      width="20%"
      v-model="openUploadDocument"
      bg-variant="white"
      right
      backdrop
      shadow
      title="Subir documentos"
    >
      <div class="p-1">
        <b-form @submit.prevent="uploadDocuments" ref="form">
          <b-form-group label="Tipo de verificación">
            <b-form-select :disabled="true" v-model="verificationType" :options="verificationTypes" />
          </b-form-group>

          <b-form-group label="Parte frontal documento de identidad">
            <b-form-file accept="jpg,jpeg,png" browse-text="Examinar" placeholder="Elige una imagen" :required="true" v-model="front" />
          </b-form-group>

          <b-form-group label="Parte trasera documento de identidad">
            <b-form-file accept="jpg,jpeg,png" browse-text="Examinar" placeholder="Elige una imagen" :required="true" v-model="back" />
          </b-form-group>

          <b-form-group label="Selfie con documento de identidad">
            <b-form-file accept="jpg,jpeg,png" browse-text="Examinar" placeholder="Elige una imagen" :required="true" v-model="selfie" />
          </b-form-group>

          <div class="text-right mt-3">
            <b-button type="submit" variant="primary" :disabled="uploadDocumentLoading">Subir</b-button>
          </div>
        </b-form>
      </div>
    </b-sidebar>
  </b-overlay>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import moment from 'moment'
import ShowDocumentModal from './components/ShowDocumentModal'
import { confirmAlert, inputAlert } from '@/helpers/utils'
import OtherUserData from './components/OtherUserData.vue'
import Transactions from '@/views/Transactions'

moment.locale('es')
import {
  BOverlay,
  BButton,
  BCard,
  BRow,
  BCol,
  BAvatar,
  BTable,
  BBadge,
  BPagination,
  BDropdown,
  BDropdownItem,
  BSidebar,
  BCardText,
  VBTooltip,
  BTab,
  BTabs,
  BForm,
  BFormSelect,
  BFormFile,
  BFormGroup
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { mapState } from 'vuex'
import DatePicker from 'vue2-datepicker'

import numberMixins from '@/mixins/numberMixins'
import Recharges from './Recharges.vue'
import Withdrawals from './Withdrawals.vue'
import SendMoney from './SendMoney.vue'
import { verifyPermission } from '@/router';

export default {
  components: {
    OtherUserData,
    DatePicker,
    BBadge,
    vSelect,
    BOverlay,
    BButton,
    BCard,
    BRow,
    BCol,
    BAvatar,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BSidebar,
    BCardText,
    Recharges,
    Withdrawals,
    SendMoney,
    BTab,
    BTabs,
    ShowDocumentModal,
    Transactions,
    BForm,
    BFormSelect,
    BFormFile,
    BFormGroup,
  },
  mixins: [numberMixins],
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  computed: {
    ...mapState({
      loading: (state) => state.clientDetail.loading,
      client: (state) => state.clientDetail.detail,
    }),
  },
  data() {
    return {
      showDocument: false,
      urlDocument: '',
      verifyFile: null,
      permissions_requerid: [],
      openUploadDocument: false,
      verificationTypes: [
        { value: 'identity', text: 'Identidad' },
        { value: 'address', text: 'Direccion' },
      ],
      verificationType: 'identity',
      front: null,
      back: null,
      selfie: null,
      uploadDocumentLoading: false,
    }
  },
  watch: {},
  mounted() {
    // Verify
    let approve_document = verifyPermission({ namePerm: 'approve-document' })
    let reject_document = verifyPermission({ namePerm: 'reject-document' })


    let permissions_requerid_verify = []
    if (approve_document) {
      permissions_requerid_verify.push("approve-document")
    }
    if (reject_document) {
      permissions_requerid_verify.push("reject-document")
    }
    this.permissions_requerid = permissions_requerid_verify
  },
  methods: {
    closeDocumentModal() {
      this.showDocument = false
    },
    async uploadDocuments() {
      if(this.uploadDocumentLoading) return
      const payload = {
        id: this.client.id,
        verificationType: this.verificationType,
        front: this.front,
        back: this.back,
        selfie: this.selfie,
      }
      this.uploadDocumentLoading = true
      const res = await this.$store.dispatch('clients/uploadDocuments', payload)
      this.uploadDocumentLoading = false
      if (res.success) {
        this.$bvToast.toast('Documentos subidos correctamente', {
          title: 'Éxito',
          variant: 'success',
          solid: true,
        })
        this.openUploadDocument = false
        this.$refs.form.reset()
        this.front = null
        this.back = null
        this.selfie = null
        this.getDetail()
      } else {
        this.$bvToast.toast(res.message, {
          title: 'Error',
          variant: 'danger',
          solid: true,
        })
      }
    },
    showUploadDocument() {
      this.openUploadDocument = true
    },
    async approve(id, status, type) {
      let res = {
        isConfirmed: false,
        value: '',
      }
      if (status !== 'approved') {
        res = await inputAlert(
          'Rechazar documento',
          '¿ Esta seguro de rechazar este documento ?',
          'Ingrese el motivo'
        )
      } else {
        res = await confirmAlert(
          'Aprobar documento',
          '¿ Esta seguro de aprobar este documento ?'
        )
      }

      if (!res.isConfirmed) return

      this.documentVerify(id, status, type, res?.value)
    },
    async documentVerify(id, status, type, motive_rejected) {
      const res = await this.$store.dispatch('clientDetail/documentVerify', {
        id,
        status: status,
        type,
        motive_rejected: status !== 'approved' ? motive_rejected : '',
      })
    },
    getNameFileType(type) {
      switch (type) {
        case 'front':
          return 'Frente'
        case 'backDocument':
          return 'Trasera'
        case 'selfie':
          return 'Selfie'
        default:
          return 'No definido'
      }
    },
    showFile(doc) {
      this.urlDocument = doc.url
      this.showDocument = true
      this.verifyFile = doc
    },
    getDetail() {
      this.$store.dispatch('clientDetail/getDetail', {
        id: this.$route.params.id,
      })
    },
  },
  created() {
    this.getDetail()
  },
}
</script>
