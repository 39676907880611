<template>
  <div class="item">
    <div class="icon" :style="{ background: color }">
      <component :is="icon" size="20" />
    </div>

    <div class="right">
      <span class="count">{{ count }}</span>
      <p class="title">{{ title }}</p>
      <!-- <p>Recargas: {{ recharges }}</p>
      <p>Retiros: {{ withdrawals }}</p> -->
    </div>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import ExportIcon from '@/components/icons/Export'
import ImportIcon from '@/components/icons/Import'
import PeopleIcon from '@/components/icons/People'
import WalletIcon from '@/components/icons/Wallet'
import CheckListIcon from '@/components/icons/CheckList'

export default {
  props: {
    icon: {
      type: String,
      default: 'ExportIcon',
    },
    count: {
      type: [Number, String],
      default: 0,
    },
    recharges: {
      type: Number,
      default: 0,
    },
    withdrawals: {
      type: Number,
      default: 0,
    },
    title: {
      type: String,
      default: 'Title',
    },
    color: {
      type: String,
      default: '#6bf182',
    },
  },
  components: {
    BCard,
    ExportIcon,
    ImportIcon,
    PeopleIcon,
    WalletIcon,
    CheckListIcon,
  },
}
</script>

<style scoped>
.item {
  display: flex;
  align-items: center;
  background: white;
  margin: 0rem 1rem;
  padding: 1rem 2rem;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  border-radius: 0.5rem;
  height: 100%;
}

.count {
  font-size: 1.6rem;
  font-weight: 600;
}

.right {
  margin-left: 2rem;
}

p {
  font-weight: bold;
}

.icon {
  background: red;
  height: 60px;
  width: 60px;
  min-width: 60px;
  max-width: 60px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

/* Display lapto or less*/
@media (max-width: 1380px) {
  .item {
    margin: 0rem 0.5rem;
    padding: 0.5rem 0.5rem;
  }
  .count {
    font-size: 1.2rem;
  }
  .icon {
    height: 50px;
    width: 50px;
    min-width: 50px;
    max-width: 50px;
  }

  .title {
    font-size: 1rem;
  }
}

</style>
