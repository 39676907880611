<template>
<svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.8002 10.9499C13.6102 10.9499 13.4202 10.8799 13.2702 10.7299C12.9802 10.4399 12.9802 9.95994 13.2702 9.66994L21.4702 1.46994C21.7602 1.17994 22.2402 1.17994 22.5302 1.46994C22.8202 1.75994 22.8202 2.23994 22.5302 2.52994L14.3302 10.7299C14.1902 10.8699 14.0002 10.9499 13.8002 10.9499Z" :fill="color"/>
<path d="M17.83 11.7499H13C12.59 11.7499 12.25 11.4099 12.25 10.9999V6.16992C12.25 5.75992 12.59 5.41992 13 5.41992C13.41 5.41992 13.75 5.75992 13.75 6.16992V10.2499H17.83C18.24 10.2499 18.58 10.5899 18.58 10.9999C18.58 11.4099 18.24 11.7499 17.83 11.7499Z" :fill="color"/>
<path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H11C11.41 1.25 11.75 1.59 11.75 2C11.75 2.41 11.41 2.75 11 2.75H9C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V13C21.25 12.59 21.59 12.25 22 12.25C22.41 12.25 22.75 12.59 22.75 13V15C22.75 20.43 20.43 22.75 15 22.75Z" :fill="color"/>
</svg>
</template>
<script>
export default {
    props: {
        size: {
            type: [ String, Number ],
            default: 24,
        },
        color: {
            type: String,
            default: "white",
        },
    },
}
</script>