<template>
<span class="balance-badge">
    Balance: $
    {{ numberFormat(amount) }}
</span>
</template>
<script>
import numberMixins from '@/mixins/numberMixins'
export default {
    props: {
        amount: {
            type: [Number, String],
            default: 0,
        },
        color: {
            type: String,
            default: "currentColor",
        },
    },
    mixins: [numberMixins],
}
</script>
<style scoped>
.balance-badge {
    font-size: 1.2rem;
    font-weight: 600;
    color: #3d52ff;
    background: #3d52ff1a;
    padding: 11px 20px;
    border-radius: 2rem;
}
</style>