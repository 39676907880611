<template>
<svg :width="size" :height="size" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M17.6191 9.62012H12.3691C11.9591 9.62012 11.6191 9.28012 11.6191 8.87012C11.6191 8.46012 11.9591 8.12012 12.3691 8.12012H17.6191C18.0291 8.12012 18.3691 8.46012 18.3691 8.87012C18.3691 9.28012 18.0391 9.62012 17.6191 9.62012Z" :fill="color"/>
<path d="M7.12055 10.3798C6.93055 10.3798 6.74055 10.3098 6.59055 10.1598L5.84055 9.40982C5.55055 9.11982 5.55055 8.63982 5.84055 8.34982C6.13055 8.05982 6.61055 8.05982 6.90055 8.34982L7.12055 8.56982L8.84055 6.84982C9.13055 6.55982 9.61055 6.55982 9.90055 6.84982C10.1905 7.13982 10.1905 7.61982 9.90055 7.90982L7.65055 10.1598C7.51055 10.2998 7.32055 10.3798 7.12055 10.3798Z" :fill="color"/>
<path d="M17.6191 16.6201H12.3691C11.9591 16.6201 11.6191 16.2801 11.6191 15.8701C11.6191 15.4601 11.9591 15.1201 12.3691 15.1201H17.6191C18.0291 15.1201 18.3691 15.4601 18.3691 15.8701C18.3691 16.2801 18.0391 16.6201 17.6191 16.6201Z" :fill="color"/>
<path d="M7.12055 17.3798C6.93055 17.3798 6.74055 17.3098 6.59055 17.1598L5.84055 16.4098C5.55055 16.1198 5.55055 15.6398 5.84055 15.3498C6.13055 15.0598 6.61055 15.0598 6.90055 15.3498L7.12055 15.5698L8.84055 13.8498C9.13055 13.5598 9.61055 13.5598 9.90055 13.8498C10.1905 14.1398 10.1905 14.6198 9.90055 14.9098L7.65055 17.1598C7.51055 17.2998 7.32055 17.3798 7.12055 17.3798Z" :fill="color"/>
<path d="M15 22.75H9C3.57 22.75 1.25 20.43 1.25 15V9C1.25 3.57 3.57 1.25 9 1.25H15C20.43 1.25 22.75 3.57 22.75 9V15C22.75 20.43 20.43 22.75 15 22.75ZM9 2.75C4.39 2.75 2.75 4.39 2.75 9V15C2.75 19.61 4.39 21.25 9 21.25H15C19.61 21.25 21.25 19.61 21.25 15V9C21.25 4.39 19.61 2.75 15 2.75H9Z" :fill="color"/>
</svg>
</template>
<script>
export default {
    props: {
        size: {
            type: [ String, Number ],
            default: 24,
        },
        color: {
            type: String,
            default: "white",
        },
    },
}
</script>