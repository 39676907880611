<template>
  <div>
    <b-overlay :show="false" rounded="sm">
      <div class="row mb-1">
        <!-- <aave-icon color="red" size="30" type="outline" /> -->

        <!-- <div class="col-6">
          <card-welcome v-if="user" :name="user.full_name" :avatarUrl="user.avatar_url" />
        </div> -->

        <div class="d-flex justify-content-between w-100 p-1 mb-2">
          <div>
            <button-count :disabled="user && !user.manage_transactions.includes('recharges')"
              icon="import-icon"
              @click="changeTransactionType('recharge')"
              :active="transactionType === 'recharge'"
              :count="totalRechargesCount"
              :text="'Recargas'" class="mr-2"/>

            <button-count :disabled="user && !user.manage_transactions.includes('withdrawals')"
              @click="changeTransactionType('withdrawal')"
              :active="transactionType === 'withdrawal'"
              :count="totalWithdrawalsCount"
              :text="'Retiros'"
              class="mr-2"
            />

            <button-count
              icon="send-icon"
              @click="changeTransactionType('sendMoney')"
              :active="transactionType === 'sendMoney'"
              :count="moneyTransfersCount"
              :text="'Transferencias'"
              class="mr-2"
            />

           <balance-badge :amount="transactionType === 'recharge' ? completedRechargesBalance : transactionType === 'withdrawal' ? completedWithdrawalsBalance : completedMoneyTransfersBalance" />
          </div>
          <div class="d-flex align-items-center">
            <filters-table @applyFilters="applyFilters" class="mr-2"/>
            <drop-down-filters @change="changeFilter"/>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <recharges-dashboard :counters="rechargeCounters" :filters="filters" :userId="userId" v-show="transactionType === 'recharge'" />
          <withdrawals-dashboard :counters="withdrawalCounters" :filters="filters" :userId="userId" v-show="transactionType === 'withdrawal'" />
          <send-money :counters="moneyTransfersCounters" :filters="filters" :userId="userId" v-show="transactionType === 'sendMoney'"></send-money>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import { BOverlay, BCard, BButton } from 'bootstrap-vue'
import numberMixins from '@/mixins/numberMixins'
import CardCount from '@/components/CardCount'
import CardWelcome from '@/components/CardWelcome'
import RechargesDashboard from '@/components/RechargesDashboard'
import WithdrawalsDashboard from '@/components/WithdrawalsDashboard'
import { mapState, mapGetters } from 'vuex'
import moment from 'moment'
import DropDownFilters from '@/components/DropDownFilters'
import SendMoney from '@/views/SendMoney'
import ButtonCount from '@/components/ButtonCount'
import BalanceBadge from '@/components/BalanceBadge'
import FiltersTable from '@/components/FiltersTable'

export default {
  props: {
    userId: {
      type: String,
      default: null
    }
  },
  components: {
    BCard,
    BOverlay,
    DatePicker,
    CardCount,
    CardWelcome,
    BButton,
    RechargesDashboard,
    WithdrawalsDashboard,
    DropDownFilters,
    SendMoney,
    ButtonCount,
    BalanceBadge,
    FiltersTable
  },
  mixins: [numberMixins],
  data() {
    return {
      rangePicker: [
        new Date(new Date().getFullYear(), new Date().getMonth(), 1),
        new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0),
      ],
      transactionType: 'recharge',
      filters: {
        dates: [
          moment().startOf('day').toDate(),
          moment().endOf('day').toDate(),
        ]
      }
    }
  },
  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      pendingCount: (state) => state.transactions.pendingCount,
      completedCount: (state) => state.transactions.completedCount,
      rejectedCount: (state) => state.transactions.rejectedCount,
      pendingRechargesCount: (state) => state.transactions.pendingRechargesCount,
      completedRechargesCount: (state) => state.transactions.completedRechargesCount,
      rejectedRechargesCount: (state) => state.transactions.rejectedRechargesCount,
      pendingWithdrawalsCount: (state) => state.transactions.pendingWithdrawalsCount,
      completedWithdrawalsCount: (state) => state.transactions.completedWithdrawalsCount,
      rejectedWithdrawalsCount: (state) => state.transactions.rejectedWithdrawalsCount,
      preApprovedRechargesCount: (state) => state.transactions.preApprovedRechargesCount,
      completedRechargesBalance: (state) => state.transactions.completedRechargesBalance,
      completedWithdrawalsBalance: (state) => state.transactions.completedWithdrawalsBalance,
      totalRechargesCount: (state) => state.transactions.totalRechargesCount,
      totalWithdrawalsCount: (state) => state.transactions.totalWithdrawalsCount,
      moneyTransfersCount: (state) => state.transactions.moneyTransfersCount,
      completedMoneyTransfersBalance: (state) => state.transactions.completedMoneyTransfersBalance,
      rechargeCounters: (state) => {
        return {
          pendingRechargesCount: state.transactions.pendingRechargesCount,
          completedRechargesCount: state.transactions.completedRechargesCount,
          rejectedRechargesCount: state.transactions.rejectedRechargesCount,
          preApprovedRechargesCount: state.transactions.preApprovedRechargesCount
        }
      },
      withdrawalCounters: (state) => {
        return {
          pendingWithdrawalsCount: state.transactions.pendingWithdrawalsCount,
          completedWithdrawalsCount: state.transactions.completedWithdrawalsCount,
          rejectedWithdrawalsCount: state.transactions.rejectedWithdrawalsCount,
        }
      },
      moneyTransfersCounters: (state) => {
        return {
          moneyTransfersCount: state.transactions.moneyTransfersCount,
          moneyTransfersMaintenanceCount: state.transactions.moneyTransfersMaintenanceCount
        }
      },
      ...mapGetters({
        totalTransactions: 'transactions/totalTransactions',
        // totalRechargesCount: 'dashboard/totalRechargesCount',
        // totalWithdrawalsCount: 'dashboard/totalWithdrawalsCount',
      }),
    }),
  },
  watch: {
    rangePicker() {
      if (this.rangePicker.length > 1) {
        this.getTransactionsInfo()
      }
    },
    // listen deep user changes
    user: {
      handler() {
        // console.log(this.user)
        // rechargetType from value user.manage_transactions
      },
      deep: true,
    },
  },
  methods: {
    applyFilters(filters) {
      this.filters = {
        ...this.filters,
        ...filters
      }
      this.getTransactionsInfo(filters)
    },
    changeFilter(range) {
      this.filters.dates = range
      this.getTransactionsInfo()
    },
    changeTransactionType(type) {
      this.transactionType = type
    },
    async getTransactionsInfo() {
      try {
        this.$store.dispatch('transactions/getTransactionsInfo', {
          ...this.filters,
          userId: this.userId
        })
      } catch (error) {
        console.log(error)
      }
    },
  },
  created() {
    this.getTransactionsInfo()
  },
  mounted() {
    if (this.user && this.user.manage_transactions.includes('recharges')) {
      this.transactionType = 'recharge'
    } else if (
      this.user &&
      this.user.manage_transactions.includes('withdrawals')
    ) {
      this.transactionType = 'withdrawal'
    }
  },
}
</script>

<style lang="scss" scoped>
.active {
  background-color: #3d52ff !important;
  color: #fff !important;
}

.gap-25px {
  gap: 25px;
}
</style>
