
<template>
  <div>
    <b-dropdown no-caret id="dropdown-form" ref="dropdown" class="d" right>
        <template #button-content>
            <setting-icon color="#1a2ab1" size="20"/>
        </template>
      <b-dropdown-form>
        <b-form-group label="Metodo de pago" label-for="dropdown-form-email" @submit.stop.prevent>
            <b-form-select v-model="paymentMethod" @change="selectPaymentMethod" style="width: 350px">
                <b-form-select-option v-for="paymentMethod in paymentMethods" :key="paymentMethod.id" :value="paymentMethod.id">
                    {{ paymentMethod.name }}
                </b-form-select-option>
            </b-form-select>
        </b-form-group>

        <b-form-group label="Medio de pago" label-for="dropdown-form-password">
            <b-form-select v-model="bank" style="width: 350px">
                <b-form-select-option @click="selectBank" v-for="bank in banks" :key="bank.id" :value="bank.id">
                    {{ bank.name }}
                </b-form-select-option>
            </b-form-select>
        </b-form-group>
        <b-form-group label="Gestor" label-for="dropdown-form-password">
             <b-form-select @change="selectGestor" v-model="gestor" style="width: 350px">
                <b-form-select-option v-for="g in gestores" :key="g.id" :value="g.id">
                    {{ g.full_name }}
                </b-form-select-option>
            </b-form-select>
        </b-form-group>
        <b-button variant="primary" size="sm" @click="applyFilters()">Aplicar</b-button>
      </b-dropdown-form>
    </b-dropdown>
  </div>
</template>

<script>
import { BDropdown,
BDropdownDivider,
BDropdownItem,
BDropdownForm,
BFormSelect,
BButton,
BFormGroup,
BFormSelectOption
} from 'bootstrap-vue'
import SettingIcon from '@/components/icons/Setting'
import API from '@/libs/axios'

export default {
  props: {},
  components: {
    BDropdown,
    BDropdownDivider,
    BDropdownItem,
    BDropdownForm,
    SettingIcon,
    BFormSelect,
    BButton,
    BFormGroup,
    BFormSelectOption
  },
  data() {
    return {
      currentOption: null,
      isCloseable: false,
      customRange: '',
      paymentMethods: [
        { id: null, name: 'Todos'},
      ],
      banks:[
        { id: null, name: 'Todos'},
      ],
      gestores: [
        { id: null, full_name: 'Todos'},
      ],
      paymentMethod: null,
      bank: null,
      gestor: null,
    }
  },
  computed: {
  },
  methods: {
    applyFilters() {
      this.$emit('applyFilters', {
        paymentMethod: this.paymentMethod,
        bank: this.bank,
        gestor: this.gestor,
      })
      this.$refs.dropdown.hide()
    },
    selectGestor(gestorId) {
      this.gestor = gestorId
    },
    selectBank(bankId) {
      this.bank = bankId
    },
    selectPaymentMethod(pmId) {
      const pm = this.paymentMethods.find(pm => pm.id === pmId)
      // const banks = pm.crypto_method ? pm.currencies : pm.banks
      const banks = pm.banks
      // Add all banks option
      this.banks = [
        { id: null, name: 'Todos'},
      ]

      if(banks && banks.length > 0) {
        this.banks = [
          ...this.banks,
          ...banks
        ]
      }

      this.bank = null
    },
    async getPaymentMethods() {
      try {
        const { data: response } = await API.get('get-payment-methods', {
          params: {},
        })

        console.log(response)
        this.paymentMethods = [
          { id: null, name: 'Todos'},
          ...response.data
        ]
      } catch (error) {
        console.log(error)
      }
    },
    async getGestores() {
      try {
        const { data: response } = await API.get('get-gestores', {
          params: {},
        })

        console.log(response)
        this.gestores = [
          { id: null, full_name: 'Todos'},
          ...response.data
        ]
        this.gestor = null
      } catch (error) {
        console.log(error)
      }
    },
    handleHide(bvEvent) {
      if (!this.isCloseable) {
        bvEvent.preventDefault();
      } else {
        this.$refs.dropdown.hide();
      }
    },
    closeDropdown() {
      this.isCloseable = true;
      this.$refs.dropdown.hide();
    },
    handleSelect(option) {
    },
  },
  mounted() {
    this.getPaymentMethods()
    this.getGestores()
  },
}
</script>

<style>
.d button.dropdown-toggle{
    background: white !important;
    border: 1px solid #f1f1f1 !important;
    padding: 0.5rem 1rem !important;
    margin: 0.1rem;
}
</style>
