<template>
    <b-button :disabled="disabled"
        @click="$emit('click')"
        :class="[
        'custom-button',
        'btn',
        { active: active, inactive: !active },
        ]"
    >
    <span class="d-flex align-items-center">
        <span class="d-flex align-items-center">
            <span class="mr-1">
                <component :is="icon" :color="colorIcon" size="16"/>
            </span>

            <span>
            {{ text }}
            </span>
        </span>

        <span :class="['ml-1 custom-circle mr-1 d-flex', {'custom-circle-active': active}]">{{ count }}</span>
    </span>
    </b-button>
</template>
<script>
import { BButton } from 'bootstrap-vue'
import ExportIcon from '@/components/icons/Export'
import ImportIcon from '@/components/icons/Import'
import SendIcon from '@/components/icons/Send'

export default {
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
        active: {
            type: Boolean,
            default: false,
        },
        text: {
            type: String,
            default: 'Click me!',
        },
        count: {
            type: Number,
            default: 0,
        },
        icon: {
            type: String,
            default: 'ExportIcon',
        },
    },
    components: {
        BButton,
        ExportIcon,
        ImportIcon,
        SendIcon,
    },
    computed: {
        colorIcon() {
            return this.active ? '#fff' : '#3d52ff'
        },
    },
}
</script>
<style scoped>
.custom-button {
  background-color: #fff !important;
  color: rgb(41, 41, 41) !important;
  border: none;
}
.active {
  background-color: #3d52ff !important;
  color: #fff !important;
}
.inactive {
  background-color: #fff !important;
}
.custom-circle {
  color: black;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  display: inline-block;
  text-align: center;
  line-height: 20px;
  padding: 0 5px;
}

.custom-circle-active {
  color: white;
}

</style>